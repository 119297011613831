import type { Cuid } from "@codesandbox/pitcher-client";
import type { Port } from "@codesandbox/pitcher-protocol/dist/src/messages/port";
import { env } from "environment-interface/env";
import type { TabParams } from "features/types/tabs";
import { withBasePath } from "isomorphic/utils";
import { commands } from "vscode";

export const VSCODE_INSTALL_URL = "https://code.visualstudio.com/";
export const VSCODE_INSIDERS_INSTALL_URL =
  "https://code.visualstudio.com/insiders/";

export const LEGACY_AUTO_LAUNCH_VSCODE_KEY = "DONT_SHOW_VSCODE_INSTALL_PROMPT";
export const LOCAL_EDITOR_PREFERENCE = "LOCAL_EDITOR_PREFERENCE";

export interface GetVsCodePromptUrlOptions {
  branchId: string;
  activeFileId?: Cuid | null;
  insiders?: boolean;
}

/** Generate a URL to the "Launching VS Code" page */
export function getVsCodePromptUrl({
  branchId,
  activeFileId,
  insiders,
}: GetVsCodePromptUrlOptions): string {
  const baseUrl = withBasePath(env, "/vscode");
  const queryParams = new URLSearchParams({
    branchId,
  });

  if (activeFileId) {
    queryParams.set("fileId", activeFileId);
  }

  // Persist the VS Code Insiders choice in the URL
  // so that "Open in VS Code" button on the prompt page
  // uses the correct "vscode-insiders:" protocol.
  if (insiders) {
    queryParams.set("insiders", "true");
  }

  return `${baseUrl}?${queryParams.toString()}`;
}

export function executeVSCodeCommand<C extends keyof VSCodeCommans>(
  command: C,
  commandOptions?: VSCodeCommans[C],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  vscodeOptions?: any,
) {
  commands.executeCommand(command, commandOptions, vscodeOptions);
}

/**
 * Built-in commands available here:
 * https://code.visualstudio.com/api/references/commands
 */
type VSCodeCommans = {
  "codesandbox.openCsbTab": TabParams;
  "codesandbox.layout.closeDevtool": TabParams;
  "codesandbox.tasks.createTask": undefined;
  "codesandbox.layout.openDevtool": undefined;
  "codesandbox.openPreviewFromPort": { port?: Port };
  "codesandbox.layout.showRawEditor": undefined;
  "codesandbox.layout.toggleDevtools": undefined;
  "codesandbox.terminal.open": { taskId: string };

  // Workbench commands
  "workbench.explorer.fileView.focus": undefined;
  "workbench.scm.focus": undefined;
  "workbench.files.action.enableReadOnly": undefined;
  "workbench.files.action.disableReadOnly": undefined;
  "workbench.files.action.refreshFilesExplorer": undefined;
  "workbench.action.toggleSidebarVisibility": undefined;
  "workbench.action.terminal.toggleTerminal": undefined;
  "workbench.action.triggerReconnect": undefined;
  "workbench.action.pauseSocketWriting": undefined;
  "workbench.action.files.revert": undefined;

  "editor.action.inlineSuggest.trigger": undefined;
  "git.refresh": undefined;
  "timeline.removeView": undefined;
  "github.api.preloadPullRequest": undefined;
};
